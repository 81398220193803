<script setup lang="ts">

</script>

<template>
  <div class="flex w-screen h-screen items-center justify-center">
    <main>
      <slot />
    </main>
  </div>
</template>
